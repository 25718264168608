import { ReactComponent as DashedLine } from '../../../SVGIcons/dashed-line.svg';
import { ReactComponent as HorizontalDashedLine } from '../../../SVGIcons/horizontal-dashed.svg';
import { ReactComponent as CopyIcon } from '../../../SVGIcons/copy-2.svg';
import { ReactComponent as ViewIcon } from '../../../SVGIcons/view.svg';
import { ReactComponent as ViewOffIcon } from '../../../SVGIcons/view-off.svg';
import { useState } from 'react';
import { quantwisePlainImage } from '../../../images/Support';
import { toast } from 'react-toastify';
import useCopyTextHook from '../../../hooks/useCopyTextHook';
import { usePlatformAction } from '../../../store/platform/platformAction';
import { RotatingLines } from 'react-loader-spinner';
import { QUANTWISE_CONSTANT } from '../../../utils/constants';

interface Props {
  backgroundColor: string;
  voucher: {
    _id: string;
    orderId: string;
    productId: string;
    validity: string;
    type: string;
    userBID: string;
    voucherCode: string;
    status: string;
    redeemedAt: string;
    expiredAt: null | string;
    createdAt: string;
    updatedAt: string;
  };
}

const VoucherCard = ({ backgroundColor, voucher }: Props) => {
  const [visible, setVisible] = useState(false);
  const { writeClipboardText } = useCopyTextHook();
  const { platformLogin } = usePlatformAction();
  const [loading, setLoading] = useState(false);
  const handleCopy = async () => {
    writeClipboardText(voucher.voucherCode);
    toast.success('Copied to clipboard');
  };

  const handlePlatformLogin = async () => {
    setLoading(true);
    const res = await platformLogin(QUANTWISE_CONSTANT.externalId);
    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }

    setLoading(false);
  };
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="card w-full md:w-fit rounded-lg md:px-2.5 text-white relative before:absolute before:size-72 before:-left-32 before:-bottom-32 before:bg-white/30 before:rounded-full overflow-hidden bg-bubbleStar bg-no-repeat bg-right-top"
    >
      <div className="w-1 rounded-lg h-[calc(100%-32px)] bg-white absolute left-0 top-1/2 transform -translate-y-1/2 mx-2 hidden md:block"></div>

      <div className="relative z-10 flex flex-col py-4 md:flex-row md:py-0">
        <div className="flex flex-col items-center justify-center px-5 lg:px-7 md:py-7">
          <img
            src={quantwisePlainImage}
            alt="Quantwise logo"
            className="mx-auto border-2 border-[#97D6FF] bg-white h-9 w-9 mb-2 rounded-lg overflow-hidden object-contain	"
          />
          <h5 className="mb-1 text-lg font-bold">QUANTWISE</h5>
          <div className="block mb-1 text-lg font-bold md:hidden">
            {voucher?.type?.toUpperCase()}&nbsp;
            {voucher?.validity?.toUpperCase()}
          </div>
          <p className="mb-2 text-[11.5px]  font-light md:hidden block">
            Subscription without our limited-time coupon.
          </p>
          <div className="mb-2 text-xs font-medium text-black">
            Your Voucher Code
          </div>
          <div className="flex justify-end items-center px-2 py-1 rounded bg-white/15 voucher-dashed-border mb-3 min-w-[186px] ">
            <div className="text-xs font-medium text-black pl-1 mr-2.5 mx-auto">
              {visible ? voucher.voucherCode : 'XXXXXXXXXXXXXXXX'}
            </div>
            {visible && (
              <button
                className="flex items-center justify-center w-5 h-5 bg-white border rounded-md border-primary/50"
                onClick={handleCopy}
              >
                <CopyIcon />
              </button>
            )}
            <button
              className="bg-white border  border-primary/50 rounded-md flex items-center justify-center h-5 w-5 ml-0.5"
              onClick={() => setVisible(!visible)}
            >
              {visible ? <ViewOffIcon /> : <ViewIcon />}
            </button>
          </div>
        </div>

        <DashedLine className="hidden h-auto -mt-3 shrink-0 md:block " />
        <HorizontalDashedLine className="w-full md:hidden" />

        <div className="flex flex-col items-center justify-center mt-1 md:items-start md:py-6 md:px-6 md:mt-0">
          <div className="hidden mb-1 text-xl font-bold md:block">
            {voucher?.type?.toUpperCase()}&nbsp;
            {voucher?.validity?.toUpperCase()}
          </div>
          <p className="hidden mb-4 font-light md:block">
            Subscription without our limited-time coupon.
          </p>
          {
            (voucher.status === 'ACTIVE' || voucher.status === 'GENERATED') &&
            <button
              className='bg-white text-primary flex justify-center items-center rounded-md border border-primary px-2 py-1.5 text-xs drop-shadow-md mx-auto md:mx-0 min-w-[88px]'
              onClick={handlePlatformLogin}
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  width="15"
                  strokeWidth="3"
                  animationDuration="0.75"
                  strokeColor="#1b9fda"
                />
              ) : (
                'Redeem now'
              )}
            </button>
          }
        </div>
      </div>
      <div className="w-1 rounded-lg h-[calc(100%-32px)] bg-white absolute right-0 top-1/2 transform -translate-y-1/2 mx-2 hidden md:block"></div>
    </div>
  );
};

export default VoucherCard;
