import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../../../store/superNode/superNodeSlice.ts';
import GlobalSkeleton from '../../../../components/Global/GlobalSkeleton.tsx';
import { AmountType, setDecimalPlaces } from '../../../../utils/utils.ts';

const TotalGASKSection = () => {
  const { totalGask, getTotalGaskLoading } = useSelector(selectSuperNodeSlice);

  return (
    <div className="relative p-[.08rem] rounded-lg overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-[#1B9FDA] to-[#0E173F] md:rounded-md xxs:rounded-[4px]"></div>
      <div className="relative bg-white xxs:px-5 md:px-4 md:py-3 xxs:py-1 rounded-md flex justify-between items-center">
      <span className="font-[Rubik] xxs:text-base md:text-2xl font-medium text-primary">
              Total GASK
            </span>
            {
              !totalGask && getTotalGaskLoading ? (
                <GlobalSkeleton animationValue="pulse" height={40} width={150} />
              ):(
                <span className="font-[Rubik] xxs:text-2xl md:text-4xl font-medium md:text-black">
              {setDecimalPlaces(Number(totalGask), AmountType.DOLLAR)}
            </span>
              )
            }
      </div>
    </div>
  );
};

export default TotalGASKSection;
