import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GlobalSkeleton from '../../../components/Global/GlobalSkeleton';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import { TTotalProduction } from '../../../types/superNodes.type';
import DividerInfoComponent from './DividerInfoComponent';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';

const renderTotalProductStats = () => {
  const TotalProduction: TTotalProduction[] = [
    {
      title: 'My Production',
      value: 20000,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">
            My Production
          </div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            Displays the total rewards you have earned from staking in NodeK.
          </div>
        </div>
      ),
    },
    {
      title: 'Team Production',
      value: 1000,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">
            Team Production
          </div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            Shows the total rewards your entire team has earned as a result of
            staking in NodeK.
          </div>
        </div>
      ),
    },
    {
      title: 'First Line Production',
      value: 10000,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">
            First Line Production
          </div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            Represents the total rewards obtained by your first line users from
            staking in NodeK.
          </div>
        </div>
      ),
    },
  ];
  return TotalProduction;
};

const renderStats = (data: any) => {
  const DailyStats = [
    {
      title: 'Insufficient GASK',
      value: data?.insufficientGask || 0,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 mr-5 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">
            Insufficient GASK
          </div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            This loss occurs when your GASK balance is exhausted, halting
            Supernode reward calculations. To continue earning rewards, ensure
            you stake more to increase your GASK balance.
          </div>
        </div>
      ),
    },
    {
      title: 'Daily Cap',
      value: data?.dailyCapping || 0,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">Daily Cap</div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            Indicates the loss due to reaching your daily cutoff limit. You only
            receive rewards up to this limit. To avoid this, consider purchasing
            higher-value machines with a higher daily limit.
          </div>
        </div>
      ),
    },
    {
      title: 'Inactive First Line',
      value: data?.inActiveFirstUser || 0,
      tooltipContent: (
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg">
          <div className="text-xs font-medium text-defaultBlack">
            Inactive First Line
          </div>

          <div className="font-normal text-defaultBlack text-xs max-w-xs min-w-[6rem] w-full mx-auto box-border">
            This value shows the loss incurred due to inactive first line users,
            which reduces the levels of base referral bonuses and the rewards
            you can earn. Encourage your first line users to stay active to
            minimize this loss.
          </div>
        </div>
      ),
    },
  ];
  return DailyStats;
};

const DividerInfoComponentLoading = ({
  totalLostSection,
}: {
  totalLostSection?: boolean;
}) => {
  return (
    <div className="flex flex-col h-[100%] justify-between ">
      <GlobalSkeleton animationValue="pulse" height={30} width={100} />
      <section className="flex flex-col md:flex-row md:justify-between ">
        {Array(3)
          .fill({})
          ?.map((stats, index) => (
            <React.Fragment key={index}>
              <div
                className={`flex flex-col xxs:w-full md:w-auto md:items-start xxs:flex-row xxs:justify-between `}
              >
                <div className="flex gap-3 md:flex-col md:justify-start xxs:flex-row xxs:w-full xxs:justify-between xxs:items-end md:items-start">
                  <GlobalSkeleton
                    animationValue="pulse"
                    height={30}
                    width={100}
                  />
                  <div className="">
                    <GlobalSkeleton
                      animationValue="pulse"
                      height={30}
                      width={100}
                    />
                  </div>
                </div>
              </div>
              {/* {index < data.length - 1 && (
              <div
                className="hidden md:block w-[1px] bg-gray-300"
                style={{ backgroundColor: textColor }}
              />
            )} */}
            </React.Fragment>
          ))}
      </section>
      {totalLostSection && (
        <div
          className={`flex gap-5  xxs:justify-between md:justify-start items-end`}
          // style={{ color: textColor }}
        >
          <GlobalSkeleton animationValue="pulse" height={30} width={100} />
          {/* <GlobalSkeleton
            animationValue="pulse"
            height={30}
            width={80}
          /> */}
        </div>
      )}
    </div>
  );
};

const TotalStatsRender = () => {
  const {
    currentLossOfRewardsLoss,
    superNodeProductData,
    getProductionLoading,
    getCurrentLossOfRewardsLoading,
  } = useSelector(selectSuperNodeSlice);
  const [totalProduction, setTotalProduction] = useState<
    TTotalProduction[] | any
  >();

  useEffect(() => {
    setTotalProduction(
      renderTotalProductStats().map((item) => {
        switch (item.title) {
          case 'My Production':
            return {
              ...item,
              value: superNodeProductData?.myProduction,
              unit: superNodeProductData?.token?.symbol,
            };
          case 'Team Production':
            return {
              ...item,
              value: superNodeProductData?.teamProduction,
              unit: superNodeProductData?.token?.symbol,
            };
          case 'First Line Production':
            return {
              ...item,
              value: superNodeProductData?.firstLineProduction,
              unit: superNodeProductData?.token?.symbol,
            };
          default:
            return item;
        }
      })
    );
  }, [superNodeProductData]);

  return (
    <section className="flex flex-col w-full gap-4">
      <HoverBorderAnimation className="bg-white col-span-2 rounded-md md:py-8 md:px-8 xxs:px-4 xxs:py-4 border border-borderBlue max-h-[205px] h-full radius-5px">
        {superNodeProductData?.myProduction === 0 && getProductionLoading ? (
          <DividerInfoComponentLoading />
        ) : (
          <DividerInfoComponent
            mainTitle="TOTAL PRODUCTION"
            data={totalProduction}
            textColor="black"
            totalLostSection={false}
          />
        )}
      </HoverBorderAnimation>
      <HoverBorderAnimation className="h-full col-span-2 rounded-md bg-friendsRedGradient md:py-8 md:px-8 xxs:px-4 xxs:py-4 radius-5px">
        {currentLossOfRewardsLoss.netTotal === 0 &&
        getCurrentLossOfRewardsLoading ? (
          <DividerInfoComponentLoading totalLostSection={true} />
        ) : (
          <DividerInfoComponent
            mainTitle="Current Loss of Rewards"
            data={renderStats(currentLossOfRewardsLoss)}
            textColor="white"
            totalLostSection={true}
            totalLoss={currentLossOfRewardsLoss?.netTotal}
          />
        )}
      </HoverBorderAnimation>
    </section>
  );
};

export default TotalStatsRender;
