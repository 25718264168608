import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthHeader from './AuthHeader';
import PrelineSetup from '../PrelineSetup';
import { useSelector } from 'react-redux';
import { selectUserSlice } from '../../store/user/userSlice';
import { PAGES } from '../../utils/routers';
import { extractSSOParams } from '../../utils/helper';
import { christmasFooter, christmasImage5 } from '../../../src/images/index'
import { christmasImage1 } from '../../../src/images/index'
import { christmasImage2 } from '../../../src/images/index'
import { christmasImage3 } from '../../../src/images/index'
import { christmasImage4 } from '../../../src/images/index'

export default function AuthLayout(): JSX.Element {
  const navigate = useNavigate();
  const { user } = useSelector(selectUserSlice);

  useEffect(() => {
    if (user) {
      const ssoParams = localStorage.getItem('ssoUrl');
      if (ssoParams) {
        let params: any = extractSSOParams(ssoParams?.toString() as string);
        const { redirect_uri, scope, response_type, client_id } = params;
        if (redirect_uri && scope && response_type && client_id) {
          return navigate('/sso' + ssoParams, {
            replace: true,
          });
        }
      }
      navigate(PAGES.DASHBOARD);
    }
  }, [user]);

  return (
    <PrelineSetup>
      <div className="flex flex-col gap-4 items-center bg-christamsBg min-h-screen">
        <AuthHeader />
        <div className="flex items-center justify-between w-full max-w-[1300px] p-0 md:gap-28 lg:gap-48 pt-5 sm:px-8 px-4 relative">
          <div className='w-full absolute -top-4 xl:-left-3'>
            <img src={christmasImage5} className='animate-pendulum' />
          </div>
          <div className='flex justify-between w-full absolute -top-4 px-8'>
            <img src={christmasImage1} className='animate-pendulum2' />
            <img src={christmasImage2} className='animate-pendulum' />
            <img src={christmasImage3} className='z-[10] animate-pendulum2' />
            <img src={christmasImage4} className='z-[10] h-[60px] animate-pendulum' />
          </div>
          <Outlet />
        </div>
      </div>
      <div className='w-full bg-christamsBg'>
        <img src={christmasFooter} className='w-full h-[100px] md:h-full' />
      </div>
    </PrelineSetup>
  );
}
