import { useEffect, useState } from 'react';

export default function useDebounce(
  value: any,
  delay: number,
  loaderFunction?: () => void
): any {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      loaderFunction && loaderFunction();
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
