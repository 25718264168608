import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
import { homnifiLogo } from '../../images';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import { selectNotification } from '../../store/notifications/notificationsSlice';
import { CustomTab, CustomTabs } from './CustomTabs';
import TabContent from './TabContent';

const NotificationsPopOver = () => {
  const containerRef = useRef(null);
  const { getHomnifiNotification, readAllHomnifiNotifications } =
    useNotificationsActions();
  const { notificationData, unReadNotificationCount, infinitLoader } =
    useSelector(selectNotification);
  const [currentPage, setCurrentPage] = useState<number>(
    notificationData?.currentPage ?? 1
  );
  const [selected, setSelected] = React.useState('all');

  const handleSelectionChange = (key: any) => {
    setSelected(key);
  };

  const getAllHomnifiNotifications = async (page: number) => {
    await getHomnifiNotification(page);
  };

  const markAllAsReadNotifications = async () => {
    await readAllHomnifiNotifications();
  };

  const handleScroll = () => {
    const container: any = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      if (currentPage < notificationData.totalPages) {
        setCurrentPage(currentPage + 1);
        getAllHomnifiNotifications(currentPage + 1);
      }
    }
  };

  console.log('infinitLoader', infinitLoader && notificationData?.list?.length);

  return (
    <div className="flex-col hidden md:flex w-[100vw] md:absolute top-2 right-0 md:w-96 bg-white border border-borderLightGray shadow-lg h-auto rounded-lg">
      <div className="flex items-center justify-between w-full p-4">
        <span className="text-lg font-medium">Notifications</span>
        {unReadNotificationCount > 0 && (
          <button
            className="font-medium text-primary"
            onClick={() => {
              if (unReadNotificationCount > 0) {
                markAllAsReadNotifications();
              }
            }}
          >
            Mark all as read
          </button>
        )}
      </div>
      <CustomTabs
        selectedKey={selected}
        onSelectionChange={handleSelectionChange}
      >
        <CustomTab id="all" title="All" />
        <CustomTab id="transaction" title="Transactions" />
        {/* <CustomTab id="coming-soon" title="Coming Soon" /> */}
      </CustomTabs>
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="max-h-[65vh] overflow-auto small-scroll border border-b-1"
      >
        {infinitLoader && notificationData?.list?.length === 0 ? (
          <div className="flex justify-center w-full my-2">
            <span className="px-4 text-lg font-normal text-primary">
              Loading
            </span>
            <Spinner className="w-8 h-8 animate-spin text-primary" />
          </div>
        ) : (
          <TabContent activeTab={selected} />
        )}
      </div>
      <div className="flex justify-center w-full p-4 ">
        <img src={homnifiLogo} alt="Logo" className="w-24" />
      </div>
    </div>
  );
};

export default NotificationsPopOver;
