import React, { useState, memo, useMemo, FC } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import useDebounce from '../../../../../hooks/useDebounceHook';

import LinearProgressBar from '../../../../../components/Progress';
import { formatDateToString } from '../../../../../utils/helper';
import moment from 'moment';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import { useEffect } from 'react';
import { IActivePool } from "../../../../../types/countryPool.type";
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';

type ActivePoolProps = {
  data: IActivePool;
};
const ActivePool: FC<ActivePoolProps> = ({ data }) => {
  const { getCountryPool } = useCountryPoolActions();
  const [activePool, setActivePool] = useState(data);
  const [resetFilter, setResetFilter] = useState(false);
  const [isLoading, setLoading] = useState({
    searchLoading: false,
    dataFilter: false,
  });
  const [filterActiveValue, setFilterActiveValue] = React.useState<any>({
    page: 1,
    limit: 10,
    query: null,
    fromDate: null,
    toDate: null,
  });

  const debouncedSearchValue = useDebounce(
    filterActiveValue.query,
    1000,
    () => {
      filterActiveValue?.query != null
        ? setLoading({ ...isLoading, searchLoading: true })
        : (() => {})();
    }
  );

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name',
        dataClass: '!text-primaryGrey !text-sm !font-normal !pl-6 !w-[25%] ',
      },
      {
        id: 'startDate',
        label: 'Start Date',
        dataClass: '!text-primaryGrey !text-sm !font-normal',
      },
      {
        id: 'status',
        label: 'Status',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[30%]',
      },
      {
        id: 'total',
        label: 'Total Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] ',
      },
      {
        id: 'maximum',
        label: 'Maximum Reward Points',
        dataClass: '!text-primaryGrey !text-sm !font-normal !w-[15%] ',
      },
    ],
    []
  );
  const handleReset = () => {
    setResetFilter(true);
    setFilterActiveValue({
      page: 1,
      limit: 10,
      query: null,
      fromDate: null,
      toDate: null,
    });
  };

  const rows = activePool?.list?.map((ele: any) => {
    return {
      name: (
        <div className="flex gap-1 justify-start items-start h-5 ">
          <div className="w-2.5 h-2.5 rounded-full bg-[#AF52DE]"></div>
          <p className="text-sm leading-none text-black font-normal">
            {ele?.name}{' '}
          </p>
        </div>
      ),
      startDate: (
        <span className="!text-black">
          {ele?.startDate
            ? formatDateToString(new Date(ele?.startDate.toString()), false)
            : '-'}
        </span>
      ),
      status: (
        <div className=" flex flex-col justify-center  text-black text-xs w-full !min-w-[140px] !max-w-[28rem]">
          <div className=" flex flex-row justify-between items-start font-normal text-xs pb-1 ">
            <p>Generated</p> <p>Available</p>
          </div>
          <LinearProgressBar
            isBlackThemed={true}
            value={
              ((Number(ele?.generatedPoints) || 1) /
                (Number(ele?.remainingPoints) || 1)) *
              100
            }
          />
          <div className=" flex flex-row justify-between items-start font-normal text-xs pt-1 ">
            <p>{Number(ele?.generatedPoints)}</p>{' '}
            <p>{Number(ele?.remainingPoints)}</p>
          </div>
        </div>
      ),
      total: (
        <div className="flex flex-row justify-start items-center">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="font-normal text-black text-sm ml-1">
            {`${ele?.totalPoints}`}
          </p>
        </div>
      ),
      maximum: (
        <div className="flex flex-row justify-start items-center">
          {/* <img src={ele?.tokenIcon} alt={ele.name} className="w-5 h-5" /> */}
          <p className="font-normal text-black text-sm ml-1">
            {`${ele?.rewardAmount}`}
          </p>
        </div>
      ),
    };
  });
  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilterActiveValue({
      ...filterActiveValue,
      ...{ fromDate: from, toDate: to },
    });
  };
  const tableDropdowns = (
    <button
      className="text-primary text-xs font-medium h-10"
      type="button"
      onClick={handleReset}
    >
      Reset
    </button>
  );

  useEffect(() => {
    setResetFilter(false);
    setLoading({ ...isLoading, dataFilter: true });
    getCountryPool(filterActiveValue).then(() => {
      setLoading({
        searchLoading: false,
        dataFilter: false,
      });
    });
  }, [
    filterActiveValue.page,
    filterActiveValue.limit,
    debouncedSearchValue,
    filterActiveValue.fromDate,
    filterActiveValue.toDate,
  ]);

  useEffect(() => setActivePool(data), [data]);

  return (
    <HoverBorderAnimation
      className={`h-full border radius-5px !rounded-lg  w-full col-span-12 bg-white  relative !p-0`}
      hoverAnimation={true}
    >
      <p className="text-lg font-semibold mb-3 p-6 pb-3 ">Active Pool</p>
      <GlobalTable
        isCellClass={true}
        stopHoverEffect={true}
        filterClass="!px-6"
        containerStyles="!border-none rounded-lg"
        isLoading={isLoading?.dataFilter}
        bodyStyles="!text-black !font-normal"
        totalPages={1}
        searchIsLoading={isLoading?.searchLoading}
        searchFieldPlaceholderText="Search"
        handlePageChange={(page: any) => {
          setFilterActiveValue({ ...filterActiveValue, ...{ page } });
        }}
        setSearchValue={(e: any) => {
          setFilterActiveValue({ ...filterActiveValue, ...{ query: e } });
        }}
        // tableWidth="true"
        handleDateDate={handleDateDate}
        tableDropdowns={tableDropdowns}
        resetDateFilter={resetFilter}
        toolTipItems={['remarks']}
        colorItems={['status']}
        fromDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
        toDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
        dateArrowAllow={true}
        paginationPosition="right"
        rows={rows?.length ? rows : []}
        headCells={headCells}
        searchValue={filterActiveValue?.query}
        showTotalRecords={false}
        bodyClass={'!min-h-0 !rounded-md'}
      ></GlobalTable>
    </HoverBorderAnimation>
  );
};

export default memo(ActivePool);
