import { homnifiLogo } from '../../images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../../store/user/userActions';
import { selectUserSlice } from '../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { PLATFORM_NAME } from '../../utils/constants';
import { christmasCap } from '../../../src/images/index'
const AuthHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userLogout } = useUserActions();
  const { user } = useSelector(selectUserSlice);

  return location.pathname === '/auth/signin' ? (
    // For Signup
    <div className="flex flex-col justify-between 2xl:justify-around items-center w-full gap-4 px-12 2xl:px-0 py-7 text-sm sm:flex-row bg-white border-b border-christmasBorder">
      <div className='relative'>
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="w-36 cursor-pointer"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
        <img
          src={christmasCap}
          className='absolute h-7 w-10 -top-4 -left-4'
        />
      </div>
      <div className="w-full xs:w-auto">
        Don't have any account yet?{' '}
        <button
          onClick={() => navigate('/auth/signup')}
          className="font-medium text-primary"
        >
          Sign Up
        </button>
      </div>
    </div>
  ) : (
    // For Login
    <div className="flex flex-col justify-between 2xl:justify-around w-full gap-4 px-12 2xl:px-0 py-8 text-sm sm:flex-row bg-white border-b border-christmasBorder">
      <div className='relative'>
        <img
          src={homnifiLogo}
          alt={`${PLATFORM_NAME} Logo`}
          className="w-36 cursor-pointer"
          onClick={() => (window.location.href = 'https://homnifi.com')}
        />
        <img
          src={christmasCap}
          className='absolute h-7 w-10 -top-4 -left-4'
        />
      </div>
      {!user ? (
        <div className="w-full xs:w-auto">
          Already have an account?{' '}
          <button
            onClick={() => {
              // userLogout();
              navigate('/auth/signin');
            }}
            className="font-medium text-primary"
          >
            Sign In
          </button>
        </div>
      ) : (
        <div className="w-full xs:w-auto">
          Not {user?.email}?{' '}
          <button onClick={userLogout} className="font-medium text-primary">
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthHeader;
