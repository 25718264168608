//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  platform: any;
  featuredPlatform: any;
  vouchersData: any;
  favoritePlatform: any;
  currentPlatformLogin: string;
  pagesEnable: any;
  platformLoginLoading: boolean;
  featuredPlatformLoading: boolean;
  favoritePlatformLoading: boolean;
  userVouchersLoading: boolean;
  adsList: any;
  adsLoading: boolean;
}

const initialState: State = {
  platform: [],
  vouchersData: [],
  featuredPlatform: [],
  favoritePlatform: [],
  currentPlatformLogin: '',
  platformLoginLoading: false,
  userVouchersLoading: false,
  favoritePlatformLoading: false,
  featuredPlatformLoading: false,
  pagesEnable: null,
  adsList: [],
  adsLoading: false,
};

const slice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatform: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setPagesEnable: (state, action: PayloadAction<any>) => {
      return { ...state, pagesEnable: action.payload };
    },
    setAds: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setPlatform, setPagesEnable, setAds } = slice.actions;
export const selectPlatorfom = (state: { platform: State }) => state.platform;
export default slice.reducer;
