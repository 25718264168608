import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import CardLayout from '../../../../../components/Cards/CardLayout/index.tsx';
import GlobalTable from '../../../../../components/Global/GlobalTable.tsx';
import useDebounce from '../../../../../hooks/useDebounceHook.ts';
import { useSNGPPoolActions } from '../../../../../store/sngpPool/sngpPoolActions.ts';
import {
  ISNGPScoreHistory,
  TSNGPScoreHistory,
} from '../../../../../types/sngpPool.type.ts';
import { format } from 'date-fns';

type ScoreHistoryTableProps = {
  title: string;
  data: TSNGPScoreHistory;
  loading: boolean;
};
const headCells = [
  {
    id: 'minter',
    label: 'Minter Name',
    dataClass: 'w-[35%]',
  },
  {
    id: 'value',
    label: 'Purchase Value',
    dataClass: 'w-[30%]',
  },
  {
    id: 'sngp',
    label: 'SNGP',
    dataClass: 'w-[15%]',
  },
  {
    id: 'date',
    label: 'Date',
    dataClass: 'w-[30%]',
  },
];

const ScoreHistoryCommunity: FC<ScoreHistoryTableProps> = ({
  title,
  data,
  loading,
}) => {
  const { getSNGPScoreHistory } = useSNGPPoolActions();

  const [searchValue, setSearchValue] = React.useState<any>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [resetFilter, setResetFilter] = useState(false);
  const [tableData, setTableData] = useState<Array<ISNGPScoreHistory>>([]);
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [shouldFetchData, setShouldFetchData] = useState(true);

  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    selectedStatus: '',
    fromDate: null,
    toDate: null,
    totalPages: 0,
    totalCount: 0,
  });

  const handleDateDate = (startDate: any, endDate: any) => {
    const from = moment(startDate).format('YYYY-MM-DD');
    const to = moment(endDate).format('YYYY-MM-DD');
    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));
    setShouldFetchData(true);
  };

  useEffect(() => {
    if (shouldFetchData) {
      const fetchData = async () => {
        setResetFilter(false);
        const response = await getSNGPScoreHistory({
          page: filters.page,
          limit: 10,
          query: debouncedSearchValue,
          fromDate: filters?.fromDate,
          toDate: filters?.toDate,
          type: filters?.selectedStatus,
        });
        setTableData(response?.data?.data?.list);
        setTotalPages(data.totalPages || 0);
        setTotalCount(data.totalCount || 0);
      };
      fetchData();
    }
    setShouldFetchData(true);
  }, [filters, debouncedSearchValue]);

  const handleReset = () => {
    setSearchValue('');
    setFilters({
      limit: 10,
      page: 1,
      selectedStatus: '',
      fromDate: null,
      toDate: null,
      totalPages: 0,
      totalCount: 0,
    });
    setResetFilter(true);
    debouncedSearchValue?.length && setShouldFetchData(false);
  };

  useEffect(() => {
    setTableData(data?.list);
  }, [data]);

  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    return format(date, 'dd-MM-yyyy - HH:mm');
  };

  return (
    <CardLayout className="col-span-12 " isBorder={false}>
      <span className="mb-2 text-xl font-medium text-black "> {title}</span>

      <section className="flex flex-col items-start justify-between gap-4">
        <GlobalTable
          rows={
            tableData
              ? tableData.map((item: ISNGPScoreHistory) => {
                  const { machineName, purchaseValue, date, sngp } = item;

                  return {
                    minter: (
                      <p className="text-xs font-normal text-primaryGrey">
                        {machineName || ''}
                      </p>
                    ),
                    value: (
                      <p className="text-xs font-normal text-black">
                        {purchaseValue || 0}
                      </p>
                    ),
                    sngp: (
                      <p className="text-xs font-normal text-black">
                        {sngp || 0}
                      </p>
                    ),
                    date: <span>{formatDate(date)}</span>,
                  };
                })
              : []
          }
          headCells={headCells}
          searchIsLoading={false}
          resetDateFilter={resetFilter}
          tableContainerClassname=" !rounded-md"
          page={filters.page}
          totalPages={totalPages}
          handlePageChange={(page) => {
            setFilters({ ...filters, page });
            setShouldFetchData(true);
          }}
          copyItems={['destination']}
          handleDateDate={handleDateDate}
          emptyDataMessage="No Rewards History!"
          setSearchValue={(e: any) => {
            setSearchValue(e ? e : null);
            setShouldFetchData(true);
          }}
          searchValue={searchValue}
          showTotalRecords={false}
          searchFieldPlaceholderText="Search"
          paginationPosition={'right'}
          toolTipItems={['remarks']}
          colorItems={['status']}
          fromDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
          toDateClassName="py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]"
          dateArrowAllow={true}
          headStyles="h-[2.5rem] !font-[400] md:!text-[11px] xxs:!text-[12px] text-primaryGrey"
          bodyStyles="flex h-[3.5rem]"
          isLoading={tableData?.length === 0 ? loading : false}
          tableWidth={'w-full'}
          tableDropdowns={
            <div className="flex flex-row space-x-3 sm:items-center">
              <button
                className="h-10 text-xs font-medium text-primary"
                type="button"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          }
        />
      </section>
    </CardLayout>
  );
};

export default ScoreHistoryCommunity;
