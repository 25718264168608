import GlobalHeading from '../../components/Global/GlobalHeading';
import { useEffect, useState } from 'react';
import VoucherCard from './components/VoucherCard';
import useUserCheck from '../../hooks/useUserCheck';
import { usePlatformAction } from '../../store/platform/platformAction';
import { EmptyVoucher } from '../../images/svgs';
import { voucherEmptyIcon } from '../../images/others';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import { selectPlatorfom } from '../../store/platform/platformSlice';
import { useSelector } from 'react-redux';

const colors = {
  active: ['#48C7FF', '#FFBA53', '#C5A1FF', '#99DFFF', '#72E9CC'],
  redeemed: '#A8C1D3',
  expired: '#858585',
};
const Vouchers = () => {
  const { vouchersData, userVouchersLoading } = useSelector(selectPlatorfom);

  const { user } = useUserCheck();
  const { getUserVouchers } = usePlatformAction();

  useEffect(() => {
    if (!userVouchersLoading) {
      getUserVouchers(user!.id);
    }
  }, []);
  return (
    <div className="">
      <GlobalHeading
        heading={`My Vouchers`}
        className="flex-1 !text-defaultBlack mb-4"
      />

      {userVouchersLoading && vouchersData.length === 0 ? (
        <>
          {Array(1)
            .fill('')
            .map(() => (
              <GlobalSkeleton
                animationValue="wave"
                variant="rectangular"
                width={'55%'}
                className="relative w-full my-4 bg-white border rounded-lg shadow-lg cursor-pointer"
                height={180}
              />
            ))}
        </>
      ) : (
        <>
          {vouchersData.length > 0 ? (
            <div className="flex flex-col gap-5">
              {vouchersData.map((voucher: any, index: number) => (
                <VoucherCard
                  key={voucher.voucherCode}
                  voucher={voucher}
                  backgroundColor={
                    voucher.status === 'REDEEMED'
                      ? colors.redeemed
                      : voucher.status === 'EXPIRED'
                        ? colors.expired
                        : colors.active[index % colors.active.length]
                  }
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-1 bg-white justify-center h-[60vh] items-center w-full rounded-3xl border border-[#E9E9E9]">
              <div className="flex flex-col items-center w-full gap-6">
                <div className="flex items-center justify-center h-auto">
                  <div className="relative">
                    <EmptyVoucher />
                    <img
                      src={voucherEmptyIcon}
                      alt="No vouchers"
                      className="absolute top-7 left-[138px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <span className="text-primary xxs:text-sm  sm:text-[18px] font-medium">
                    Sorry, No active vouchers available
                  </span>
                  <span className="text-[#7D7D7D] xxs:text-[10px]  sm:text-xs font-normal">
                    Check back later for exciting offers!
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Vouchers;
