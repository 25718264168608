/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { usePlatformAction } from '../../../store/platform/platformAction';
import { useSettingAction } from '../../../store/setting/settingActions';
import {
  selectSetting,
  TPlatformSettings,
} from '../../../store/setting/settingSlice';
import { SHOP_CONSTANT } from '../../../utils/constants';
import { TextHoverEffect } from '../../../utils/SvgHoverEffect';
import { Spinner } from '../../../images/svgs';
import { ReactComponent as DashboardBannerSVG } from '../../../SVGIcons/Dashboard/dashboardBanner.svg';

type Props = {
  membership: boolean;
  activeStep: string;
};

const DashboardBanner = ({ membership, activeStep }: Props): ReactElement => {
  const [videoURL, setVideoURL] = useState<string>('');
  const { platformLogin } = usePlatformAction();

  const { platformSettings, getPlatformSettingsLoading } =
    useSelector(selectSetting);

  console.log('platformSettings', videoURL);

  // let VideoSource: string =
  //   platformSettings?.find(
  //     (video: TPlatformSettings): boolean =>
  //       video.category === 'dashboard' &&
  //       video.type === 'general' &&
  //       video.name === 'featured-video-url'
  //   )?.value || '';

  const isXl: boolean = useMediaQuery({ query: '(min-width: 1280px)' });

  const handleLogin = async () => {
    const res = await platformLogin(SHOP_CONSTANT.externalId);

    if (res?.status === 200) {
      window.open(res?.data?.redirectUri, '_blank');
    }
  };

  useEffect((): void => {
    let VideoSource: string =
      platformSettings?.find(
        (video: TPlatformSettings): boolean =>
          video.category === 'dashboard' &&
          video.type === 'general' &&
          video.name === 'featured-video-url'
      )?.value || '';

    if (!VideoSource || !ReactPlayer.canPlay(VideoSource)) {
      VideoSource = 'https://www.youtube.com/watch?v=iVHY0JlhjXk';
    }
    setVideoURL(VideoSource);
  }, [platformSettings]);

  return (
    <>
      {membership ? (
        <section className=" relative h-auto xl:h-[376px]  py-16 pb-10  flex items-center  ">
          {/* Background SVG */}
          <div className="absolute inset-0 z-10 w-full h-full">
            <TextHoverEffect
              duration={0.1}
              svgElement={<DashboardBannerSVG />}
            />
          </div>
          {/* Gradient Overlay */}
          <div className="absolute inset-0 z-00 bg-gradient-to-t from-[#DEF7FF] to-white opacity-30" />

          <div className="w-full 2xl:w-[1200px] mx-10 2xl:mx-auto flex lg:flex-row  items-center justify-between gap-6 2xl:gap-28 flex-col z-00 pointer-events-auto">
            <article className="relative flex flex-col justify-between w-full gap-5 pointer-events-auto z-00">
              <h1 className="text-3xl font-medium sm:text-4xl font-baumans">
                Welcome To The Future Of
                <br />
                WEB3 And Decentralized
                <br />
                Applications
              </h1>
              <p className="text-[#0E173F] font-normal text-xl">
                Unlock the Future with Web3 and Decentralized Applications
              </p>

              <div></div>
            </article>
            {/* <img
              src="/img/christmas/christmasGift.svg"
              className="hidden screen1780:block absolute bottom-0 h-24 right-0 !z-40"
            /> */}
            <article className="relative z-10 flex items-center justify-center w-full h-full overflow-hidden rounded-lg">
              {getPlatformSettingsLoading && !platformSettings ? (
                <Spinner className="w-16 h-16 animate-spin text-primary" />
              ) : (
                <ReactPlayer
                  url={videoURL}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '24px',
                    zIndex: '99999',
                  }}
                  width={'100%'}
                  height={isXl ? '235px' : '235px'}
                  onError={(e): void => {
                    console.warn('A video error occurred:', e);
                    // Optionally, display fallback content here
                  }}
                />
              )}
            </article>
          </div>
        </section>
      ) : (
        <section className=" relative  py-12 pb-5 flex items-center h-auto  xl:h-[376px] ">
          {/* Background SVG */}
          <div className="absolute inset-0 z-10 w-full h-full">
            <TextHoverEffect
              duration={0.1}
              svgElement={<DashboardBannerSVG />}
            />
          </div>
          {/* Gradient Overlay */}
          <div className="absolute inset-0 z-00 bg-gradient-to-t from-[#DEF7FF] to-white opacity-30" />
          {/* <img
            src="/img/christmas/christmasSanta.png"
            className="hidden screen1780:block absolute bottom-0 right-0 !z-40"
          /> */}
          <div className="w-full 2xl:w-[1200px] mx-10 2xl:mx-auto  flex lg:flex-row  items-center justify-between gap-6  2xl:gap-28 flex-col   z-00 pointer-events-auto">
            <article className="relative flex flex-col justify-between w-full gap-3 pointer-events-auto z-00">
              <p className="text-[#0E173F] font-normal text-xl">
                Simple and straightforward.
              </p>
              <h1 className="text-3xl font-normal sm:text-4xl font-baumans">
                How To Start Your
                <br />
                WEB3 Journey
                <br />
              </h1>
              <p className="text-[#0E173F] font-normal text-xl">
                Follow these steps to dive into the world of Web3 and
                decentralized applications, tailored to your experience level
                and needs.
              </p>
              <p className="text-[#1B9FDA] font-normal text-xl">
                Make your first purchase to embark on this rewarding journey.
              </p>

              <div></div>
            </article>

            <article className="relative z-10 flex items-center justify-center w-full h-full overflow-hidden rounded-lg">
              {getPlatformSettingsLoading ? (
                <Spinner className="w-16 h-16 animate-spin text-primary" />
              ) : (
                <ReactPlayer
                  url={videoURL}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '24px',
                  }}
                  width={'100%'}
                  height={isXl ? '235px' : '235px'}
                  onError={(e): void => {
                    console.warn('A video error occurred:', e);
                    // Optionally, display fallback content here
                  }}
                />
              )}
            </article>
          </div>
        </section>
      )}
    </>
  );
};

export default DashboardBanner;
