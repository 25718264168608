//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TMACHINE_DATA,
  TUserProducts,
  TUserTransactionHistory,
} from '../../data/cloudk2';
import {
  IActiveBurn,
  IBurnLimit,
  IBurnUserStatus,
  IBurnWalletBalance,
  ICalculatedBurn,
  TUserRewards,
} from '../../types/cloundk.type';

export interface Clouldk2State {
  // isBurnLoading:boolean;
  isCalculatedBurnLoading: boolean;
  isBurnLoading: boolean;
  purchaseCalculatorAutoCompounding: boolean;
  purchaseCalculatorValue: number;
  timeExpired: boolean;
  userPurchasedMachinesLoading: boolean;
  activeMachines: TUserProducts[];
  machineList: TMACHINE_DATA[];
  machinePurchased: { isMachinePurshaed: boolean };
  userProducts?: TUserProducts[];
  userRewards: TUserRewards;
  claimableRewards: any;
  lifetimeRewards: any;
  globalAutoCompound: boolean;
  userTransactionHistory: TUserTransactionHistory[];

  userRewardsLoading: boolean;
  claimableRewardLoading: boolean;
  lifetimeRewardLoading: boolean;
  userMachinesLoading: boolean;
  userMachineByIdLoading: boolean;
  userGlobalAutoCompoundLoading: boolean;
  userGlobalClaimRewardsLoading: boolean;
  setGlobalMachineAutoCompoundLoading: boolean;
  userGlobalMachineRewardsLoading: boolean;
  userTransactionHistoryLoading: boolean;
  postStakeIntoMachineLoading: boolean;
  purchaseCalculatorAdvancedOption: boolean;
  purchaseCalculatorAth: string;
  purchaseCalculatorDlp: string;

  getAllProductsLoading: boolean;
  isBurnLimitLoading?: boolean;
  // stack more
  stackMoreToken?: string;
  stackMoreTokenLoading: boolean;
  stackMoreAmount: number;
  stackMorePercentage: number;
  activeBurn: IActiveBurn;
  burnUserStatus: IBurnUserStatus;
  burnWalletBalance: IBurnWalletBalance;
  calculatedBurn: ICalculatedBurn;
  burnLimit: IBurnLimit;
  isBurn: boolean;
  isAmountInputReset: boolean;
  limit: any;
  machineDetails: any;
}

export const CLOULDK2_SLICE_NAME = 'clouldk2';

const initialState: Clouldk2State = {
  isBurnLoading: false,
  isCalculatedBurnLoading: false,
  userPurchasedMachinesLoading: false,
  purchaseCalculatorAutoCompounding: false,
  purchaseCalculatorValue: 500,
  purchaseCalculatorAdvancedOption: false,
  timeExpired: false,
  machineDetails: [],
  activeMachines: [],
  machineList: [],
  machinePurchased: {
    isMachinePurshaed: false,
  },
  userRewards: {},
  claimableRewards: {},
  lifetimeRewards: {},
  globalAutoCompound: false,
  userTransactionHistory: [],
  isBurnLimitLoading: true,
  userRewardsLoading: false,
  claimableRewardLoading: false,
  lifetimeRewardLoading: false,
  userMachinesLoading: false,
  userMachineByIdLoading: false,
  userGlobalAutoCompoundLoading: false,
  userGlobalClaimRewardsLoading: false,
  setGlobalMachineAutoCompoundLoading: false,
  userGlobalMachineRewardsLoading: false,
  userTransactionHistoryLoading: false,
  postStakeIntoMachineLoading: false,
  purchaseCalculatorAth: '',
  purchaseCalculatorDlp: '',
  getAllProductsLoading: false,

  // stack more
  stackMoreTokenLoading: false,
  stackMoreAmount: 0,
  stackMorePercentage: 0,
  activeBurn: {
    _id: '',
    name: '',
    phaseUniqueCode: '',
    startAt: '',
    expiresAt: '',
    normalPercentage: 0,
    boostPercentage: 0,
    admin: '',
    status: '',
  },
  burnLimit: {
    status: false,
    totalBurnedToken: 0,
    totalBurnedAmountInDoller: 0,
  },
  calculatedBurn: {
    status: false,
    lykPrice: '',
    percentageToBeApplied: 0,
    usdKPromoWalletData: {
      amount: 0,
      dollarValue: 0,
      walletBalance: 0,
    },
    phaseWalletData: {
      amount: 0,
      dollarValue: 0,
    },
    stakeWalletData: {
      amount: 0,
      dollarValue: 0,
    },
    totalToBeStakeInMachineData: {
      totalAmount: 0,
      totalToken: 0,
      machinePrice: 0,
    },
    machine: {
      totalStaked: 0,
      totalMlykStaked: 0,
      mlykStakedAfterJoined: 0,
      smlykStakedAfterJoined: 0,
      maxStakeLimit: 0,
      boostPercentage: 0,
      boostPercentageRequiredInMlyk: 0,
      boostPercentageRequiredInMlykDoller: 0,
      normalPercentage: 0,
    },
    totalBurnAmount: {
      amount: 0,
      dollarValue: 0,
    },
  },
  burnUserStatus: {
    isJoined: false,
    usdkPromoBalance: 0,
    smlykBalance: 0,
    activeBurn: {
      _id: '',
      name: '',
      phaseUniqueCode: '',
      startAt: '',
      expiresAt: '',
      normalPercentage: 0,
      boostPercentage: 0,
      admin: '',
      status: '',
    },
  },
  burnWalletBalance: {
    bunToken: {
      _id: '',
      name: '',
      symbol: '',
      type: '',
      withdrawType: '',
      color: '',
      networks: [],
      iconUrl: '',
      createdBy: '',
      platforms: [],
      deletedAt: null,
      valueType: '',
    },
    bunWalletId: '',
    bunWalletBalance: 0,
  },
  isBurn: true,
  isAmountInputReset: false,
  limit: '',
};

const cloudk2Slice = createSlice({
  name: CLOULDK2_SLICE_NAME,
  initialState,
  reducers: {
    setClouldk2Slice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },

    setMachineDetails: (state, action: PayloadAction<any>) => {
      return { ...state, machineDetails: action.payload };
    },

    setMoreStackPercentage: (state, action: PayloadAction<any>) => {
      const { percentage, walletList } = action.payload;

      if (!percentage && !walletList) {
        return {
          ...state,
          stackMorePercentage: 0,
        };
      }

      state.stackMorePercentage = percentage;

      const balance = walletList.total || 0;

      const amount = balance * (state.stackMorePercentage / 100);
      state.stackMoreAmount = amount;
    },

    setMoreStackPercentageToken: (state, action: PayloadAction<any>) => {
      const { percentage, stakingToken } = action.payload;

      if (!percentage && !stakingToken) {
        return {
          ...state,
          stackMorePercentage: 0,
        };
      }

      state.stackMorePercentage = percentage;

      const balance = stakingToken?.balance;

      const amount = balance * (state.stackMorePercentage / 100);
      state.stackMoreAmount = amount;
    },

    setStackMoreAmount: (state, action: PayloadAction<any>) => {
      if (!action.payload) {
        return {
          ...state,
          stackMoreAmount: 0,
        };
      }

      state.stackMoreAmount = action.payload;
      state.stackMorePercentage = 0;
    },

    updateMachine: (state, action: PayloadAction<any>) => {
      const { id, data } = action.payload;

      const tempState = state;

      const machine = tempState.machineList.map((_machine: any) =>
        _machine._id === id
          ? {
              ..._machine,
              ...data,
            }
          : _machine
      );

      return { ...state, machineList: [...machine] };
    },

    setActiveBurn: (state, action: PayloadAction<IActiveBurn>) => {
      return { ...state, activeBurn: action.payload };
    },
    setCalculatedBurn: (state, action: PayloadAction<ICalculatedBurn>) => {
      return { ...state, calculatedBurn: action.payload };
    },
    setBurnLimit: (state, action: PayloadAction<IBurnLimit>) => {
      return { ...state, burnLimit: action.payload };
    },
    setBurnUserStatus: (state, action: PayloadAction<IBurnUserStatus>) => {
      return {
        ...state,
        burnUserStatus: action.payload,
        activeBurn: action.payload?.activeBurn,
      };
    },
    setTimeExpired: (state, action: PayloadAction<boolean>) => {
      return { ...state, timeExpired: action.payload };
    },
    setBurnWalletBalance: (
      state,
      action: PayloadAction<IBurnWalletBalance>
    ) => {
      return { ...state, burnWalletBalance: action.payload };
    },
    setBurn: (state, action: PayloadAction<boolean>) => {
      return { ...state, isBurn: action.payload };
    },
    setAmountInputReset: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAmountInputReset: action.payload };
    },
    setLimit: (state, action: PayloadAction<any>) => {
      return { ...state, limit: action.payload };
    },
  },
});

export const clouldk2SliceAction = cloudk2Slice.actions;
export const {
  setClouldk2Slice,
  setBurnLimit,
  updateMachine,
  setMoreStackPercentage,
  setStackMoreAmount,
  setMoreStackPercentageToken,
  setActiveBurn,
  setBurnUserStatus,
  setBurnWalletBalance,
  setCalculatedBurn,
  setTimeExpired,
  setBurn,
  setAmountInputReset,
  setLimit,
  setMachineDetails,
} = cloudk2Slice.actions;
export const selectCloudSlice = (state: {
  [CLOULDK2_SLICE_NAME]: Clouldk2State;
}) => state[CLOULDK2_SLICE_NAME];

// select single machine
export const selectCloudMachine = (
  state: {
    [CLOULDK2_SLICE_NAME]: Clouldk2State;
  },
  id: string
) =>
  state[CLOULDK2_SLICE_NAME].machineList.find((machine) => machine._id === id);

export default cloudk2Slice.reducer;
