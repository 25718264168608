import { useDispatch } from 'react-redux';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { GET_ADS, PLATFORM_LIST } from '../../utils/network/ApiEndpoints';
import { setAds, setPlatform } from './platformSlice';
import ApiRequestBID from '../../utils/network/ApiRequestBID';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getLoginToken } from '../../utils/storage/protectedStorage';
import {
  INVALID_TOKEN,
  KMALL_API_URL,
  UNAUTHORIZED,
  X_API_KEY,
} from '../../utils/constants';

export const usePlatformAction = () => {
  const dispatch = useDispatch();
  const loginToken = getLoginToken();

  const getPlatformList = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: PLATFORM_LIST,
      })
      .then((response: any) => {
        dispatch(setPlatform({ platform: response.data }));
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };
  const getUserVouchers = async (bid: string) => {
    dispatch(
      setPlatform({
        userVouchersLoading: true,
      })
    );
    return await axios
      .request({
        method: 'GET',
        url: `${KMALL_API_URL}/purchases/vouchers/${bid}/`,
        headers: {
          Authorization: `Token ${loginToken ? loginToken : null}`,
          'x-api-key': X_API_KEY,
          platform: 'CUBE_WALLET',
        },
      })
      .then((response: any) => {
        dispatch(
          setPlatform({
            vouchersData: response.data,
            userVouchersLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setPlatform({
            userVouchersLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };

  const getFeaturedPlatformList = async (loader: boolean) => {
    dispatch(
      setPlatform({
        featuredPlatformLoading: loader,
      })
    );
    return await ApiRequestBID()
      .request({
        method: 'GET',
        url: PLATFORM_LIST,
      })
      .then((response: any) => {
        dispatch(
          setPlatform({
            featuredPlatform: response.data,
            featuredPlatformLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }
        dispatch(
          setPlatform({
            featuredPlatformLoading: false,
          })
        );

        return error;
      });
  };

  const getFavoritePlatformList = async (loader: boolean) => {
    dispatch(
      setPlatform({
        favoritePlatformLoading: loader,
      })
    );
    return await ApiRequestBID()
      .request({
        method: 'GET',
        url: `${PLATFORM_LIST}?favorite=true`,
      })
      .then((response: any) => {
        dispatch(
          setPlatform({
            favoritePlatform: response.data,
            favoritePlatformLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setPlatform({
            favoritePlatformLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };

  const getAdsList = async (loader: boolean) => {
    dispatch(
      setAds({
        adsLoading: loader,
      })
    );
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_ADS,
      })
      .then((response: any) => {
        dispatch(
          setAds({
            adsList: response.data.data,
            adsLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        setAds({
          adsLoading: false,
        });
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.message || error?.data?.message) {
          toast.error(error?.message || error?.data?.message);
        } else {
          toast.error('Something went wrong!');
        }
        dispatch(
          setPlatform({
            featuredPlatformLoading: false,
          })
        );

        return error;
      });
  };

  const setFavorite = async (id: any, isFvt: boolean) => {
    return await ApiRequestBID()
      .request({
        method: 'POST',
        url: `${PLATFORM_LIST}${id}/favorite/`,
        data: {
          favorite: isFvt,
        },
      })
      .then((response: any) => {
        // dispatch(setPlatform({ favoritePlatform: response.data.data }));
        getFeaturedPlatformList(false);
        getFavoritePlatformList(false);
        return response;
      })
      .catch((error: any) => {
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };

  const platformLogin = async (id: any) => {
    dispatch(
      setPlatform({
        platformLoginLoading: true,
      })
    );

    return await ApiRequestBID()
      .request({
        method: 'POST',
        url: `${PLATFORM_LIST}${id}/login/`,
      })
      .then((response: any) => {
        dispatch(
          setPlatform({
            platformLoginLoading: false,
          })
        );
        dispatch(setPlatform({ currentPlatformLogin: response?.data }));
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setPlatform({
            platformLoginLoading: false,
          })
        );
        if (
          error?.response?.status === 401 &&
          (error?.response?.statusText === UNAUTHORIZED ||
            error?.response?.data?.detail === INVALID_TOKEN)
        )
          return;
        if (error?.data?.message || error?.message) {
          toast.error(error?.data?.message || error?.message);
        } else {
          toast.error('Something went wrong!');
        }

        // Manually throw the error to propagate it further
      });
  };

  const platformSSO = async (params: any) => {
    dispatch(
      setPlatform({
        platformSSOLoading: true,
      })
    );
    return await ApiRequestBID()
      .request({
        method: 'POST',
        url: '/oauth2/authorize/',
        data: params,
      })
      .then((response: any) => {
        dispatch(
          setPlatform({
            platformSSOLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setPlatform({
            platformSSOLoading: false,
          })
        );
        if (error?.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(error?.response?.data?.error);
        }
        return error;
      });
  };

  return {
    getPlatformList,
    getFeaturedPlatformList,
    getFavoritePlatformList,
    setFavorite,
    platformLogin,
    getAdsList,
    platformSSO,
    getUserVouchers,
  };
};
