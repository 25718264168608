import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useSocketIO from '../hooks/socketIO/useSocketIO';
import useUserCheck from '../hooks/useUserCheck';
import { selectDrawer } from '../store/drawer/drawerSlice';
import { useGeneralActions } from '../store/general/generalActions';
import {
  selectNewsletterModal,
  setNewsletterModal,
} from '../store/newsLetter/newsLetterSlice';
import { selectSocketIO } from '../store/socketIO/socketIOSlice';
import { useUserActions } from '../store/user/userActions';
import { checkPercentage } from '../store/user/userSlice';
import {
  CLOUDK_SOCKET_BASE_URL,
  PLATFORM_NAME,
  SOCKET_BASE_URL,
} from '../utils/constants';
import { PAGES } from '../utils/routers';
import ScrollToTop from '../utils/scrollToTop';
import BottomBar from './common/BottomBar';
import Navbar from './common/Navbar';
import Sidebar from './common/Sidebar';
import GlobalModal from './Global/GlobalModal';
import PrelineInit from './PrelineInit';
import { ReactComponent as Spinner } from '../SVGIcons/spinner.svg';
import { useCloudActions } from '../store/Cloudk2/cloudk2Actions';
import Snowfall from 'react-snowfall';
import logo from '../assets/snowfall.png';
import { selectConvertedPriceLoading } from '../store/general/generalSlice';

const snowflake = document.createElement('img');
snowflake.src = logo;

const images = [snowflake];

export default function Layout() {
  const { user } = useUserCheck();

  if (!user) {
    return <Navigate to={PAGES.LOGIN} replace />;
  }

  return <MainLayout />;
}

const MainLayout: React.FC = () => {
  // const peekAnimation = {
  //   hidden: { x: '-100%' },
  //   peek: { x: '0%' },
  //   partial: { x: '10%' },
  // };
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleDrawer = useSelector(selectDrawer);
  const { socket } = useSelector(selectSocketIO);
  const { user, checkLayoutValidation, fetchUser } = useUserCheck();
  const { GetConvertedPrice } = useGeneralActions();
  const [signoutLoading, setSignoutLoading] = useState(false);
  const { updateUserData, userLogout } = useUserActions();
  const isNewsletterModal = useSelector(selectNewsletterModal);
  const { getBurnUserStatus } = useCloudActions();
  const isConvertedPriceLoading = useSelector(selectConvertedPriceLoading);

  const {
    establishSocketConnection: establishSocketConnectionHomnifi,
    disconnectSocket: disconnectSocketConnectionHomnifi,
  } = useSocketIO(SOCKET_BASE_URL);

  const {
    establishSocketConnection: establishSocketConnectionCloudk,
    disconnectSocket: disconnectSocketConnectionCloudk,
  } = useSocketIO(CLOUDK_SOCKET_BASE_URL);

  const [isHomnifiConnected, setIsHomnifiConnected] = useState(false);
  const [isCloudkConnected, setIsCloudkConnected] = useState(false);

  const fetchBurnStatusApi = async () => {
    try {
      await getBurnUserStatus();
    } catch (error) {
      console.error('Error fetching burn status:', error);
    }
  };

  useEffect(() => {
    if (!socket) {
      if (!isHomnifiConnected) {
        establishSocketConnectionHomnifi();
        setIsHomnifiConnected(true);
      }
      if (!isCloudkConnected) {
        establishSocketConnectionCloudk();
        setIsCloudkConnected(true);
      }
    }
  }, [socket, isHomnifiConnected, isCloudkConnected]);

  useEffect(() => {
    checkLayoutValidation();
  }, [user]);

  useEffect(() => {
    fetchBurnStatusApi();
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(checkPercentage());

      if (!isConvertedPriceLoading) {
        GetConvertedPrice();
      }

      if (
        !localStorage.getItem('homnifi-username') ||
        !localStorage.getItem('homnifi-dateJoined')
      ) {
        updateUserData(user);
      }
    }
  }, [user]);

  const handleLogout = async () => {
    setSignoutLoading(true);
    await userLogout();
    disconnectSocketConnectionHomnifi();
    disconnectSocketConnectionCloudk();
    setSignoutLoading(false);
  };

  return (
    <>
      <PrelineInit>
        {/* <motion.img
          src={christmasPapaPeek}
          alt="Christmas Papa"
          initial={{ x: '100%', y: '100%', rotate: 0, scale: 0.2 }}
          animate={{
            x: ['100%', '0%', '100%'],
            y: ['100%', '0%', '100%'],
            rotate: [0, 20, -20, 0],
            scale: [0.2, 1, 0.4]
          }}
          transition={{
            duration: 6,
            ease: 'easeInOut',
            times: [0, 0.25, 0.5, 0.75, 1],
            repeat: 2,
          }}
          style={{
            position: 'fixed',
            bottom: '0%',
            height: '100px',
            right: '0px',
            zIndex: 1000
          }}
        /> */}
        <section
          className={`relative ${user?.isImpersonateMode ? 'border-4 border-primary' : ''}`}
        >
          {user?.isImpersonateMode && (
            <div className="text-center p-2 bg-primary text-white sticky top-0 z-[1000] flex items-center justify-center ">
              You are in Impersonate Mode
              <button
                className="bg-white px-4 py-0.5 text-sm rounded-md ms-3 text-primary font-medium"
                onClick={handleLogout}
              >
                {signoutLoading ? (
                  <Spinner className="h-5 animate-spin" />
                ) : (
                  'Stop'
                )}
              </button>
            </div>
          )}

          <Navbar drawer={toggleDrawer} />
          <div className="flex justify-center w-full">
            <Sidebar drawer={toggleDrawer} />
            <div
              className={`relative min-h-screen w-full overflow-hidden ${location.pathname === '/' ? 'bg-white' : 'px-4 md:px-10 sm:px-8 md:pt-14 md:pb-36 pt-8 pb-4 sm:pb-36'}`}
              style={{
                background:
                  'radial-gradient(circle, rgba(255, 255, 255, 0.4) 40%, rgba(255, 255, 255, 0.8) 50%, rgba(27, 159, 218, 0.1) 100%)',
                // zIndex: 10,
              }}
            >
              <img
                src="/img/christmas/hangingBalloon.svg"
                className={`fixed top-20 left-52 sm:left-72 !opacity-100 animate-pendulum z-50`}
              />
              <img
                src="/img/christmas/hanging1.svg"
                className={`fixed animate-pendulum2 z-50 top-20 left-24 md:left-96`}
              />
              <img
                src="/img/christmas/hanging2.svg"
                className={`hidden md:block fixed animate-pendulum z-50 top-20 ${window.location.pathname === '/' ? 'right-10 md:right-64' : 'right-72'}`}
              />
              <img
                src="/img/christmas/light.png"
                className={`fixed top-20 right-0 !opacity-100 z-50`}
              />
              {/* yellow blinking */}
              <div
                className="fixed right-3 top-28 w-5 h-5 z-50 rounded-full animate-blink shadow-[0_0_10px_7px_rgba(255,251,210,0.8)]"
                style={{
                  background:
                    'radial-gradient(circle, rgba(255, 250, 170, 1) 3%, rgba(255, 255, 255, 0) 100%)',
                }}
              ></div>
              {/* red blinking */}
              <div
                className="fixed right-12 top-28 w-2 h-2 z-50 rounded-full animate-blink shadow-[0_0_10px_7px_rgba(255,0,0,0.4)]"
                style={{
                  background:
                    'radial-gradient(circle, rgba(255, 0, 0, 1) 5%, rgba(255, 0, 0, 0) 100%)',
                }}
              ></div>
              {/* yellow blinking */}
              <div
                className="fixed right-[66px] top-24 w-5 h-5 z-50 rounded-full animate-blink shadow-[0_0_10px_7px_rgba(255, 250, 170, 0.8)]"
                style={{
                  background:
                    'radial-gradient(circle, rgba(255, 250, 170, 1) 3%, rgba(255, 250, 170, 0) 100%)',
                }}
              ></div>
              <Snowfall
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  minHeight: '100vh',
                  pointerEvents: 'none',
                  zIndex: 50,
                }}
                radius={[8.5, 18]}
                snowflakeCount={40}
                images={images}
              />
              {window.location.pathname === '/' ? (
                ' '
              ) : (
                <div className="absolute bottom-0 left-0 w-full h-24 -z-50">
                  <img
                    src="/img/christmas/christmasFooter.svg"
                    className="object-cover w-full h-full -z-50"
                  />
                </div>
              )}
              <ScrollToTop />
              <Outlet />
            </div>
          </div>
          <BottomBar />
          <GlobalModal
            isOpen={isNewsletterModal}
            onClose={() => {
              // dispatch(setLoginPopup(false));
              dispatch(setNewsletterModal(false));
              // setIsLogInPopClosed(prev => !prev)
            }}
            title={`Announcement: ${PLATFORM_NAME} Platform is Now Live!`}
            childrenClass="p-0"
            size="2xl"
          >
            {/* {isNewsletterModalId === "1" ? <RenderInstructionModal /> : <RenderModalContent />} */}
          </GlobalModal>
        </section>
      </PrelineInit>
    </>
  );
};
