import { useEffect, memo, FC, useState } from 'react';
import GlobalImageCardList from '../../../../../components/Global/GlobalImageCardList.tsx';
import NoData from '../../../../../components/Table/NoData.tsx';
import { IUpComingPool } from '../../../../../types/countryPool.type.ts';

import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { profileImg } from '../../../../../images';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation.tsx';
type UpcomingPoolsProps = {
  data: IUpComingPool;
};

const UpcomingPools: FC<UpcomingPoolsProps> = memo(({ data }) => {
  const [upcomingData, setUpcomingData] = useState(data);

  const pools = upcomingData?.list.map((pool) => {
    return {
      icon: pool?.countryCode,
      name: pool?.name || '-',
      date: pool?.startDate || '-',
    };
  });

  useEffect(() => {
    setUpcomingData(data);
  }, [data]);

  return (
    // <GlobalPageWrapper containerClass="flex-1 !p-0 h-[344px]  !rounded-lg ">
    <HoverBorderAnimation
      className={`h-[344px] border radius-5px !rounded-lg  col-span-12 bg-white  relative !p-0 w-full xl:w-1/2`}
      hoverAnimation={true}
    >
      <div>
        <div className="p-4 px-8">
          <p className="mb-3 text-xl font-medium ">Upcoming Pools</p>
          <p className="mb-3 text-xs font-normal">New Pools Coming soon</p>
        </div>
        <div className="h-[4px] bg-gradient-to-r mb-0 from-[#f8f8f8] to-[#f7f7f7]"></div>
        <div className="p-4 overflow-y-auto max-h-[230px] small-scroll">
          {pools?.length > 0 ? (
            pools?.map((pool, index) => {
              const poolItems = {
                icon: pool.icon,
                name: pool?.name || '-',
                date: pool?.date || '-',
              };
              const flag = poolItems.icon
                ? (poolItems.icon.toUpperCase() as FlagIconCode)
                : 'IT';
              return (
                <GlobalImageCardList
                  key={index}
                  component={
                    pool.icon ? (
                      <FlagIcon
                        code={flag}
                        className="rounded-full "
                        style={{ width: 35, height: 35, objectFit: 'cover' }}
                      />
                    ) : (
                      <img
                        src={profileImg}
                        alt="Profile"
                        className=" h-full rounded-full object-cover w-[3rem] lg:!w-[3rem] xl:!w-[3rem]"
                      />
                    )
                  }
                  country={poolItems?.name ?? ''}
                  date={poolItems?.date.toString()}
                />
              );
            })
          ) : (
            <NoData
              visible={true}
              text={'No Upcoming Pools'}
              textClassName="!text-defaultBlack"
              isWallet={false}
            />
          )}
        </div>
      </div>
    </HoverBorderAnimation>
  );
});

export default UpcomingPools;
