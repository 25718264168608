import { useEffect, useState } from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import LineChart from '../../../../../components/Charts/LineChart';
import { ISNGPCommunityGraph } from '../../../../../types/sngpPool.type.ts';
import { Spinner } from '../../../../../images/svgs';
import {
  TChartDataDefs,
  TChartDataFill,
} from '../../../../../components/types/Charts/lineChart.types.ts';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation.tsx';

type Props = {
  title?: string;
  data: Array<ISNGPCommunityGraph>;
  loading: boolean;
};

const UserSNGPCommunity = ({ title, data, loading }: Props) => {
  const [chartData, setChartData] = useState<Array<ISNGPCommunityGraph>>([]);
  const [zeroScreen, setZeroScreen] = useState<boolean>(false);

  const uniqueYValues: any = Array.from(
    new Set(data.map((dataPoint: any) => dataPoint.y))
  ).sort((a: any, b: any) => a - b);

  const yScaleMin = Math.floor(Math.min(...uniqueYValues)); // Floor the min value
  const yScaleMax = Math.ceil(Math.max(...uniqueYValues)); // Ceil the max value

  const tickCount = 5; // Number of ticks
  const step = (yScaleMax - yScaleMin) / (tickCount - 1); // Calculate step based on the range
  const gridYValues = Array.from(
    { length: tickCount },
    (_, i) => Math.round(yScaleMin + i * step) // Generate grid values based on min, max, and step
  );
  useEffect(() => {
    if (!data.length) {
      setZeroScreen(true);
    } else {
      setZeroScreen(false);
    }
    setChartData(data);
  }, [data]);

  const TotalRewardsChartDefs: TChartDataDefs = [
    {
      id: 'gradient1',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: zeroScreen ? '#E6E6E6' : '#3B82F6' },
        {
          offset: 100,
          color: zeroScreen ? '#3B82F6' : '#FFFFFF',
        },
      ],
    },
  ];
  const TotalRewardsChartFill: TChartDataFill = [
    { match: '*', id: 'gradient1' },
  ];

  return (
    <HoverBorderAnimation
      className={`h-full border radius-5px rounded-lg w-full col-span-12 bg-white  relative `}
      hoverAnimation={true}
    >
      <CardLayout className="xl:col-span-1 col-span-2 h-[400px]  !rounded-[8px] border-none">
        <article className="mx-6 py-3  text-cardPrimaryText font-normal text-base flex items-center justify-between border-b border-borderLightGray">
          <h1 className="text-black text-xl font-medium">{title}</h1>
        </article>
        {loading && chartData.length === 0 ? (
          <div className="w-full h-full mt-5">
            <div className="flex w-full h-full justify-center items-center ">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        ) : chartData.length ? (
          <article className="w-full h-full">
            <LineChart
              data={[{ id: 'Community', data: chartData }]}
              curve={'basis'}
              enableArea={true}
              defs={TotalRewardsChartDefs}
              fill={TotalRewardsChartFill}
              margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
              isInteractive={true}
              enablePoints={false}
              enableGridX={false}
              enableGridY={true}
              gridYValues={gridYValues}
              colors={zeroScreen ? ['#E6E6E6'] : ['#3B82F6']}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              legends={[]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              tootlipColor={'#3B82F6'}
              showSub={true}
              theme={{
                text: {
                  fontSize: '12px',
                  fill: '#AAAAAA',
                },
                grid: {
                  line: {
                    stroke: '#E0E0E0',
                    strokeWidth: 1,
                    strokeDasharray: '0',
                  },
                  top: {
                    line: {
                      stroke: 'transparent',
                      strokeWidth: 0,
                    },
                  },
                },
                axis: {
                  ticks: {
                    line: {
                      stroke: '#FFFFFF40',
                    },
                    text: {
                      fontSize: '9px',
                      fill: '#000000B2',
                      fontFamily: 'Rubik, sans-serif',
                      fontWeight: 400,
                      // fill: '#7D7D7D',
                    },
                  },
                },
              }}
            />
          </article>
        ) : (
          <div className="w-full h-full mt-5">
            <div className="flex w-full h-full justify-center items-center ">
              <p>No data available</p>
            </div>
          </div>
        )}
      </CardLayout>
    </HoverBorderAnimation>
  );
};

export default UserSNGPCommunity;
